body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.google-visualization-tooltip { 
  pointer-events: none; 
  background-color: #00527F; 
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);
  border: none;
}

div.google-visualization-tooltip > ul > li > span {
  color: #F5F5F5 !important;
}

/* ::-webkit-scrollbar {
  width:9px;
  height:9px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius:5px;
  border-radius:5px;
  background:#ffffff;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius:5px;
  border-radius:5px;
  background:#eeeeee;
}

::-webkit-scrollbar-thumb:hover {
  background:#dddddd;
}

::-webkit-scrollbar-thumb:window-inactive {
  background:#dddddd;
} */