.buttonWrapper {
    display: inline-block;
  }
  
  .button {
    background: #f5f5f5;
    color: #96999E;
    border: 0;
    padding-top: 11px;
    padding-bottom: 13.5px;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button svg {
    fill: #96999E;
  }
  