.editor {
  box-sizing: border-box;
  cursor: text;
  padding: 8px 0px 0px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #F1F2F1;
  color: #000;
  font-size: 14px;
}

.editorReadOnly {
  box-sizing: border-box;
  cursor: text;
  padding: 8px 0px 0px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #F1F2F1;
  color: #000;
  font-size: 14px;
}

.editor .public-DraftEditor-content {
  min-height: 75px;
}
.editorReadOnly .public-DraftEditor-content {
  min-height: 75px;
}

.public-DraftEditorPlaceholder-root {
  color: rgb(186, 186, 193);
  padding-top: 3px;
  padding-left: 3px;
  margin-bottom: -20px;
}

.editor-alignment-center div{
  text-align: center;
  }
  .editor-alignment-right div{
  text-align: right;
  }